<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    商户列表
                </div>
            </template>
            <div class="content">
                <span style="font-size:14px;margin-right:10px;">商户状态:</span>
                <el-select v-model="status" style="margin-right:10px;width:100px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="启用" value="2"></el-option>
                    <el-option label="停用" value="3"></el-option>
                </el-select>
                <span style="font-size:14px;margin-right:10px;">美团授权状态:</span>
                <el-select v-model="auth" style="margin-right:10px;width:100px;" size="small" clearable>
                    <el-option label="生效中" value="1"></el-option>
                    <el-option label="已过期" value="2"></el-option>
                    <el-option label="未授权" value="3"></el-option>
                </el-select>
                <el-input v-model="keyword" placeholder="商户代码/商户名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                <el-button type="success" @click="Add" size="small">添加</el-button>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                size="small">
                    <el-table-column label="商户编号" prop="MerchantCode"></el-table-column>
                    <el-table-column label="商户名称" prop="Merchant" min-width="200px"></el-table-column>
                    <el-table-column label="商户联系人" prop="MerchantName"></el-table-column>
                    <el-table-column label="商户联系电话" prop="MerchantMobile"></el-table-column>
                    <el-table-column label="商户状态" prop="Status">
                        <template #default='scope'>
                            <span v-if="scope.row.Status == 0" style="color:red">停用</span>
                            <span v-if="scope.row.Status == 1" style="color:#67c23a">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="结算比例(%)" prop="Rate">

                        <template #default='scope'>
                            {{scope.row.Rate/100}}
                        </template>

                    </el-table-column>
                    <el-table-column label="美团授权状态">
                        <template #default='scope'>
                            <span v-if='scope.row.ExpireDTime && (scope.row.ExpireDTime*1000 > new Date())'><el-tag type="success">生效中</el-tag></span>
                            <span v-if='scope.row.ExpireDTime && (scope.row.ExpireDTime*1000 <= new Date())'><el-tag type="danger">已过期</el-tag></span>
                            <span v-if="!scope.row.ExpireDTime"><el-tag type="info">未授权</el-tag></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="right">
                        <template #default='scope'>
                            <el-button size="small" v-if="scope.row.Status == 1" @click="DisableMerchant(scope.row.ID,scope.$index)" type="text">停用</el-button>
                            <el-button size="small" v-if="scope.row.Status == 0" @click="EnableMerchant(scope.row.ID,scope.$index)" type="text">启用</el-button>
                            <el-button size="small" @click="Edit(scope.row.ID)" type="text">编辑</el-button>
                            <el-button size="small" @click="Auth(scope.$index)" type="text">美团授权</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="false"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<style scoped>

</style>
<script>

import constant from "@/constant"
export default {
    data(){
        return {
            auth:'',
            tabledata:[],
            keyword:'',
            status:'',
            shopstatus:'',
            curpage:'1',
            pagesize:'10',
            totalcount:"",
        }
    },
    methods:{
        Auth(index){
            let merchant_id = this.tabledata[index].ID;
            let merchant_app_key = this.tabledata[index].AppKey;
            if(merchant_app_key){
                let url = "https://e.dianping.com/dz-open/merchant/auth?app_key=" + merchant_app_key + "&redirect_url=" + constant.redirect +"&state=" + merchant_id;
                window.open(url,"_blank");
            }else{
                this.$message.error("暂无APPKEY");
            }
        },
        Add(){
            this.$router.push({path:'/addmerchant'});
        },
        Edit(id){
            this.$router.push({path:'/addmerchant',query:{id:id}});
        },
        DisableMerchant(id,index){
            this.$confirm("确定要停用这个商户么？",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.disablemerchant,{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success('操作成功');
                        this.tabledata[index].Status = 0;
                    }
                });
            }).catch(()=>{
                return false;
            })
        },
        EnableMerchant(id,index){
            this.$confirm("确定要启用这个商户么？",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.enablemerchant,{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success('操作成功');
                        this.tabledata[index].Status = 1;
                        this.tabledata[index].ShopStatus = 1;
                    }
                });
            }).catch(()=>{
                return false;
            })
        },
        DisableShop(id,index){
            this.$confirm("确定要关闭该商户的店铺么？",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.disableshop,{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success('操作成功');
                        this.tabledata[index].ShopStatus = 0;
                    }
                });
            }).catch(()=>{
                return false;
            })
        },
        EnableShop(id,index){
            this.$confirm("确定要开启该商户的店铺么？",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.enableshop,{
                    headers:{
                        "Content-Type":"application/json"
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success('操作成功');
                        this.tabledata[index].ShopStatus = 1;
                    }
                });
            }).catch(()=>{
                return false;
            })
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val
            this.init();
        },
        init(){
            this.axios.get(constant.merchantlist,{
                headers:{
                    "Content-Type":"application/json"
                },
                params:{
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    status: this.status,
                    shopstatus: this.shopstatus,
                    auth:this.auth
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        }
    },
    created:function(){
        this.init();
    }
}
</script>